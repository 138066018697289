import loadable from '@loadable/component';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useCallback } from 'react';;
import FullSizeSliderItemMid from './FullSizeSliderItemMid';


const Slider = loadable(() => import("react-slick"))

const settings = {
    autoplay: false,
    arrows: false,
    infinite: true,
    speed: 750,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplaySpeed: 6000,
    pauseOnHover: false
};

function FullSizeSliderSectionMid({sliderItems}) {
  const [sliderRef, setSliderRef] = useState(null);
  const sliderWrapperRef = useRef(null)

  const getSliderRef = useCallback((node) => {
    setSliderRef(node);
  }, []);

  const generateSliderItems = useCallback(() => {
    const components = []
    sliderItems.forEach((el, index) => {
        components.push((
            <FullSizeSliderItemMid
                key={index}
                title={el.title}
                firstDescription={el.firstDescription}
                secondDescription={el.secondDescription}
                image={el.image}
                activeIndex={index}
                navLength={sliderItems.length}
                setSlide={(index) => sliderRef.slickGoTo(index)}
            />
        ))
    })

    return components
  }, [sliderItems, sliderRef])

  useEffect(() => {
    if(!sliderWrapperRef?.current || !sliderRef) return;

    const handleMouseDown = () => {
      sliderRef.slickPause()
    }

    const handleMouseUp = () => {
      sliderRef.slickPlay()
    }

    document.addEventListener("mousedown", handleMouseDown)
    document.addEventListener("touchstart", handleMouseDown)
    document.addEventListener("mouseup", handleMouseUp)
    document.addEventListener("touchend", handleMouseUp)

    return () => {
      document.removeEventListener("mousedown", handleMouseDown)
      document.removeEventListener("touchstart", handleMouseDown)
      document.removeEventListener("mouseup", handleMouseUp)
      document.removeEventListener("touchend", handleMouseUp)
    }
  }, [])

  return (
    <div className="full-size-slider-mid-container" ref={sliderWrapperRef}>
        <Slider {...settings} ref={getSliderRef}>
            {generateSliderItems()}
        </Slider>
    </div>
  )
}

export default FullSizeSliderSectionMid
