import React from 'react';
import { useMemo } from 'react';
import { useCallback } from 'react';
import useWindow from '../../../hooks/useWindow';
import CustomImage from '../../Image/Image';
import { SecondaryDescription, SecondaryTitle } from '../../Typography';
import CustomHeadingSection from '../CustomHeadingSection';
import SectionWrapper from '../SectionWrapper';

function FullSizeSliderItemMid({
  title,
  firstDescription,
  secondDescription,
  image,
  navLength,
  activeIndex,
  setSlide,
}) {
  const { isMobile, isTablet, isDesktop } = useWindow();

  const generateNavItems = useCallback(() => {
    const components = [];
    const basicClassName = 'navigation__item';

    for (let i = 0; i < navLength; i++) {
      components.push(
        <div
          className={
            activeIndex === i ? `${basicClassName} ${basicClassName}--active` : basicClassName
          }
          onClick={() => setSlide(i)}
        />
      );
    }

    return components;
  }, [activeIndex, navLength, setSlide]);

  const imageClassName = useMemo(() => {
    if (activeIndex === navLength - 1) {
      return 'last-image';
    } else if (activeIndex === 0) {
      return 'first-image';
    }
    return null;
  }, [activeIndex, navLength]);

  return (
    <div className="full-size-slider-mid">
      <SectionWrapper>
        <CustomHeadingSection
          leftContent={
            <div className="text-content">
              <div className="text-wrapper">
                <div>
                  {isMobile && <img src={image} alt="" />}
                  <SecondaryTitle>{title}</SecondaryTitle>
                  <SecondaryDescription>{firstDescription}</SecondaryDescription>
                  <SecondaryDescription>{secondDescription}</SecondaryDescription>
                </div>
                <div className="navigation__container">{generateNavItems()}</div>
              </div>
            </div>
          }
          rightContent={
            <>
              {isTablet && <img src={image} alt="" />}
              {isDesktop && <img src={image} alt="" />}
            </>
          }
        />
      </SectionWrapper>
    </div>
  );
}

export default FullSizeSliderItemMid;
